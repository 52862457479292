// This optional code is used to register a service worker.
// register() is not called by default.

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.\d{1,3}){3}$/
    )
)

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL of the service worker
        const publicUrl = new URL(
            process.env.PUBLIC_URL,
            window.location.href
        )

        if (publicUrl.origin !== window.location.origin) {
            // If the app is served from a different origin, don't register
            return
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

            if (isLocalhost) {
                // This is running on localhost, so let's check if the service worker is running
                checkValidServiceWorker(swUrl, config)

                // Add logging to localhost for developers
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'This web app is being served cache-first by a service worker. ' +
                        'To learn more, visit https://cra.link/PWA'
                    )
                })
            } else {
                // Register the service worker for production environments
                registerValidSW(swUrl, config)
            }
        })
    }
}

function registerValidSW(swUrl: string, config?: Config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing
                if (installingWorker == null) {
                    return
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // New content is available, notify the user
                            console.log('New content is available; please refresh.')

                            if (config && config.onUpdate) {
                                config.onUpdate(registration)
                            }
                        } else {
                            // Content is cached for offline use
                            console.log('Content is cached for offline use.')

                            if (config && config.onSuccess) {
                                config.onSuccess(registration)
                            }
                        }
                    }
                }
            }
        })
        .catch((error) => {
            console.error('Error during service worker registration:', error)
        })
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    // Check if the service worker exists.
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' }
    })
        .then((response) => {
            // Ensure the service worker exists
            const contentType = response.headers.get('content-type')
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // If the service worker isn't found, reload the page
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload()
                    })
                })
            } else {
                // Service worker found, proceed as usual
                registerValidSW(swUrl, config)
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            )
        })
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister()
            })
            .catch((error) => {
                console.error(error.message)
            })
    }
}
